import LineCheckCircleIcon from 'components/Luxkit/Icons/line/LineCheckCircleIcon'
import LinePhoneIcon from 'components/Luxkit/Icons/line/LinePhoneIcon'
import LineRefundIcon from 'components/Luxkit/Icons/line/LineRefundIcon'
import LineUsdCircleIcon from 'components/Luxkit/Icons/line/LineUsdCircleIcon'
import React from 'react'
import { ATOL_REGIONS, NO_24_7_SUPPORT_REGIONS } from 'constants/config/region'

export function getValueSectionContent(regionCode: string) {
  return [
    {
      key: 'bestPrice',
      heading: 'Best price guarantee',
      body: 'We negotiate the best prices so you don\'t have to. If you find a better price, we\'ll match it.',
      icon: <LineUsdCircleIcon />,
      show: true,
      link: {
        text: 'Learn more',
        href: '/best-price-guarantee',
      },
    },
    {
      key: 'atolProtected',
      heading: 'ATOL protected',
      body: 'Book your trip with confidence! ATOL protection provided for eligible flights and flight inclusive holidays.',
      icon: <LineCheckCircleIcon />,
      show: ATOL_REGIONS.includes(regionCode),
    },
    {
      key: 'customerService',
      heading: '24/7 Customer service',
      body: 'Our dedicated team is available online or on the phone anytime, whenever you need us.',
      icon: <LinePhoneIcon />,
      show: !NO_24_7_SUPPORT_REGIONS.includes(regionCode),
    },
    {
      key: 'flexibleCancellation',
      heading: 'Flexible bookings',
      body: 'Travel with peace of mind with our flexible hotel cancellation and date change policies.',
      icon: <LineRefundIcon />,
      show: true,
    },
  ]
}
