import React, { useCallback, useMemo, useRef } from 'react'
import { SEARCH_VERTICALS } from 'constants/search'
import DropdownList from 'components/Luxkit/Dropdown/List/DropdownList'
import ListItem from 'components/Luxkit/List/ListItem'
import useToggle from 'hooks/useToggle'
import LineAngleDownIcon from 'components/Luxkit/Icons/line/LineAngleDownIcon'
import { capitalise } from 'lib/string/stringUtils'
import FakeInput from 'components/Common/Form/Input/FakeInput'

interface Props {
  selectedTabKey: SEARCH_VERTICALS
  tabs: Map<SEARCH_VERTICALS, App.GlobalSearchVerticalTabV2>
  onTabSelect?: (selectedTab: SEARCH_VERTICALS) => void
}

function GlobalSearchDropDown(props: Props) {
  const { selectedTabKey, tabs, onTabSelect } = props
  const tabsArray = useMemo(() => [...tabs.entries()], [tabs])

  const prefixTriggerRef = useRef<HTMLLabelElement>(null)
  const [isPrefixDropdownOpen, togglePrefixDropdown, , closePrefixDropdown] = useToggle()

  const selectVertical = useCallback<React.MouseEventHandler<HTMLButtonElement>>((e) => {
    onTabSelect?.(e.currentTarget.dataset.key as SEARCH_VERTICALS)
    closePrefixDropdown()
  }, [closePrefixDropdown, onTabSelect])

  const selectedTab = tabs.get(selectedTabKey)

  return <>
    <FakeInput
        noValidationSpacing
        ref={prefixTriggerRef}
        startIcon={selectedTab?.Icon && <selectedTab.Icon size="M" />}
        endIcon={<LineAngleDownIcon size="M" />}
        value={capitalise(selectedTab?.label)}
        onClick={togglePrefixDropdown}
      />
    <DropdownList
      size="fill-anchor"
      open={isPrefixDropdownOpen}
      onClose={closePrefixDropdown}
      anchorRef={prefixTriggerRef}
      triggerRef={prefixTriggerRef}
      placement="bottom"
    >
      {tabsArray.map(([key, { label, Icon }]) => <ListItem
        size="large"
        key={key}
        data-key={key}
        selected={selectedTabKey === key}
        onClick={selectVertical}
        startIcon={Icon && <Icon size="M" />}
        title={capitalise(label)}
      />)}
    </DropdownList>
  </>
}

export default GlobalSearchDropDown
