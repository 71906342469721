import React from 'react'
import LineTagAltIcon from 'components/Luxkit/Icons/line/LineTagAltIcon'
import LineGlobeAltIcon from 'components/Luxkit/Icons/line/LineGlobeAltIcon'
import LinePhoneIcon from 'components/Luxkit/Icons/line/LinePhoneIcon'
import LineGlassMartiniAltIcon from 'components/Luxkit/Icons/line/LineGlassMartiniAltIcon'
import LineRefundIcon from 'components/Luxkit/Icons/line/LineRefundIcon'
import { ValueSectionItem } from 'home/components/ValuePropositionBanner/ValuePropositionItems'
import { NO_24_7_SUPPORT_REGIONS } from 'constants/config/region'

export function getFrontPageUSPContent(regionCode: string): Array<ValueSectionItem> {
  return [
    {
      key: 'save',
      icon: <LineTagAltIcon size="L" />,
      body: 'Unlock exclusive offers you won’t find elsewhere.',
      show: true,
    },
    {
      key: 'bonus',
      icon: <LineGlassMartiniAltIcon size="L" />,
      body: 'Enjoy bonus handpicked inclusions, curated just for you',
      show: true,
    },
    {
      key: 'oneStop',
      icon: <LineGlobeAltIcon size="L" />,
      body: 'Discover thousands of flights, hotels, tours, cruises & more',
      show: true,
    },
    {
      key: 'flexible',
      icon: <LineRefundIcon size="L" />,
      body: 'Flexible booking options for peace of mind',
      show: true,
    },
    {
      key: '24hrService',
      icon: <LinePhoneIcon size="L" />,
      body: 'Our customer service team is available 24/7',
      show: !NO_24_7_SUPPORT_REGIONS.includes(regionCode),
    },
    {
      key: '12hrService',
      icon: <LinePhoneIcon size="L" />,
      body: 'Our customer service team is available 9am to 9pm every day',
      show: NO_24_7_SUPPORT_REGIONS.includes(regionCode),
    },
  ]
}
