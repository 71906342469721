import React, { useContext, useMemo } from 'react'
import LayoutContainer from 'components/Common/LayoutContainer'
import Group from 'components/utils/Group'
import Heading from 'components/Luxkit/Typography/Heading'
import { useParams } from 'react-router'
import { capitalise } from 'lib/string/stringUtils'
import { HOME_PAGE_OFFER_TYPES, SupportedDestinations } from 'home/constants'
import Pane from 'components/Common/Pane'
import { ANYWHERE_PLACE_ID } from 'constants/search'
import GeoContext from 'contexts/geoContext'
import { getHomeOfferListParams } from 'home/helpers'
import { usePlaceByName } from 'hooks/usePlace'
import { MAX_CAROUSEL_ITEM_COUNT } from './common/constants'
import OfferListCarousel from 'components/OfferList/OfferListCarousel'
import { themeClassName } from 'lib/theme/themeUtils'
import useHomepageAnalytics from 'home/pages/HomePage/useHomepageAnalytics'
import { GlobalSearchTrackingProvider } from 'contexts/GlobalSearch/GlobalSearchTracking'
import SearchVariant from '../Search/SearchVariant'
import styled from 'styled-components'
import { rem } from 'polished'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import { getImageUrl } from 'lib/image/imageUtils'
import { mediaQueryUp } from 'components/utils/breakpoint'
import SearchToggle from 'components/App/Header/HeaderMain/SearchToggle'
import HeroOfferExpandedSearch from './HeroOfferExpandedSearch'

const MainGrid = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows:
    [image-start]
    auto
    [search-start]
    var(--search-top-space, auto)
    [image-end carousel-start]
    auto
    [search-end]
    auto
    [carousel-end];
    
  ${mediaQueryUp.desktop} {
    --search-top-space: ${rem(120)};
  }
`

const HERO_IMAGE = getImageUrl('hero-search-overlay', {
  gravity: 'auto',
  aspectRatio: '8:3',
  brightness: -20,
})

const HERO_IMAGE_THUMBNAIL = getImageUrl('hero-search-overlay', {
  width: '64px',
  gravity: 'auto',
  aspectRatio: '8:3',
  brightness: -20,
})

const ImageGridPseudo = styled.div`
  grid-row: image;
  grid-column: 1/-1;
  background-image: url(${HERO_IMAGE}), url(${HERO_IMAGE_THUMBNAIL});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: none;

  ${mediaQueryUp.desktop} {
    display: block;
  }
`

const CarouselGridPseudo = styled.div`
  grid-row: carousel;
  background-color: ${props => props.theme.palette.neutral.default.eight};
  grid-column: 1/-1;
`

const SearchContent = styled.div`
  grid-row: search;
  grid-column: 1/-1;
  z-index: 1;

  ${mediaQueryUp.desktop} {
    display: block;
  }
`

const ImageContent = styled.div`
  grid-row: image-start / search-start;
  grid-column: 1/-1;
  padding-block: ${rem(32)} ${rem(16)};
  display: none;

  ${mediaQueryUp.desktop} {
    display: block;
  }
`

const CarouselContent = styled(Pane)`
  grid-row: search-end / carousel-end;
  grid-column: 1/-1;
  padding-block: ${rem(24)} ${rem(48)};
`

const MobileSearchBarContainer = styled(Group)`
  padding: ${rem(24)} ${rem(16)};
  background-color: ${props => props.theme.palette.neutral.default.eight};

  ${mediaQueryUp.tablet} {
    padding: ${rem(24)} ${rem(20)};
  }
  
  @media print {
    display: none;
  }
`

const MobileSearchSection = styled.div`
  background-color: ${props => props.theme.palette.neutral.default.eight};
`

interface Props {
  className?: string;
  mobileVariant: string
}

function HeroOfferCarouselSectionWithSearch(props: Props) {
  const { className, mobileVariant } = props
  const { destination } = useParams<{ destination?: SupportedDestinations}>()
  const { currentRegionCode, geoStateName } = useContext(GeoContext)
  const destinationId = usePlaceByName(destination)?.id ?? ANYWHERE_PLACE_ID

  const filters = useMemo(() => {
    if (destination) {
      return {
        offerTypes: HOME_PAGE_OFFER_TYPES,
        limit: MAX_CAROUSEL_ITEM_COUNT,
        placeIds: [destinationId],
      }
    }
    return getHomeOfferListParams({
      region: currentRegionCode,
      subregion: geoStateName,
    })
  }, [currentRegionCode, destination, destinationId, geoStateName])

  const { onSearchEvent } = useHomepageAnalytics()

  return (
    <MainGrid className={className}>
      <ImageGridPseudo aria-hidden="true" />
      <CarouselGridPseudo aria-hidden="true" className={themeClassName('inverse')} />
      <SearchContent>
        <CSSBreakpoint min="desktop">
          <LayoutContainer>
            <GlobalSearchTrackingProvider onEvent={onSearchEvent}>
              <SearchVariant />
            </GlobalSearchTrackingProvider>
          </LayoutContainer>
        </CSSBreakpoint>
      </SearchContent>
      {!!mobileVariant && <CSSBreakpoint max="tablet" >
        <MobileSearchBarContainer direction="vertical" gap={8} className={themeClassName('inverse')}>
          <Heading variant="heading2">
            <i>More</i> with every booking
          </Heading>
          <MobileSearchSection className={themeClassName('default')}>
            {mobileVariant === 'variant_1' && <SearchToggle />}
            {mobileVariant === 'variant_2' && <HeroOfferExpandedSearch />}
          </MobileSearchSection>
        </MobileSearchBarContainer>
      </CSSBreakpoint>}
      <ImageContent className={themeClassName('inverse')}>
        <LayoutContainer>
          <Heading variant="heading2">
            <i>More</i> with every booking
          </Heading>
        </LayoutContainer>
      </ImageContent>
      <CarouselContent paletteType="inverse">
        <Group direction="vertical" gap={24}>
          <LayoutContainer>
            {!destination && <Heading variant="heading2">
              Today's top <i>exclusive</i> offers
            </Heading>}
            {destination && (
              <Heading variant="heading2">
                Today's top exclusive <i>{capitalise(destination)}</i> offers
              </Heading>
            )}
          </LayoutContainer>
          <OfferListCarousel
            filters={filters}
            className={themeClassName('inverse')}
            tileStyle="card-medium"
          />
        </Group>
      </CarouselContent>
    </MainGrid>
  )
}

export default React.memo(HeroOfferCarouselSectionWithSearch)
