import GlobalMobileInPageSearchV2 from 'components/SearchV2/Components/Mobile/GlobalMobileInPageSearchV2'
import useGlobalSearchVerticalTabsV2 from 'components/SearchV2/Hooks/useGlobalSearchVerticalTabsV2'
import { SEARCH_VERTICALS } from 'constants/search'
import React, { useState } from 'react'

function HeroOfferExpandedSearch() {
  const tabs = useGlobalSearchVerticalTabsV2()

  const [selectedTab, setSelectedTab] = useState<SEARCH_VERTICALS>([...tabs.keys()][0])

  return (
    <GlobalMobileInPageSearchV2 tabs={tabs} selectedTab={selectedTab} onTabSelect={setSelectedTab} />
  )
}

export default HeroOfferExpandedSearch
