import React, { useState } from 'react'
import useGlobalSearchVerticalTabs from 'hooks/GlobalSearch/useGlobalSearchVerticalTabs'
import { SEARCH_VERTICALS } from 'constants/search'
import GlobalSearchTabsPane from 'components/Search/GlobalSearch/GlobalSearchTabsPane'

function SearchVariant() {
  const tabs = useGlobalSearchVerticalTabs()

  const [selectedTab, setSelectedTab] = useState<SEARCH_VERTICALS>([...tabs.keys()][0])

  return (
    <GlobalSearchTabsPane tabs={tabs} selectedTab={selectedTab} onTabSelect={setSelectedTab} />
  )
}

export default React.memo(SearchVariant)
