import { useEffect, useMemo } from 'react'

import { fetchFlightDeals } from 'actions/FlightActions'
import { useAppSelector, useAppDispatch } from 'hooks/reduxHooks'
import { nonNullable } from 'lib/array/arrayUtils'

type FlightDealDetails = [
  flightDeals: Array<App.FlightDeal>,
  flightDealsLoading: boolean,
]

const emptyList: App.ApiCallState<{ dealIds: Array<string> }> = {
  fetching: true,
  dealIds: [],
  error: undefined,
}

function useFlightDeals(dealCategory: string = 'all', filters?: { originAirportCode?: string; destinationAirportCode?: string }): FlightDealDetails {
  const dispatch = useAppDispatch()
  const key = dealCategory

  useEffect(() => {
    dispatch(fetchFlightDeals(dealCategory))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, dispatch])

  const list = useAppSelector(state => state.flights.flightDealLists[key] ?? emptyList)
  const flightDeals = useAppSelector((state) => state.flights.flightDeals)

  // Temporary solution, filters will be moved to query params
  const deals = useMemo(() => {
    let dealsData = nonNullable(list.dealIds.map(id => flightDeals[id]?.deal))

    if (filters) {
      dealsData = dealsData.filter(deal => {
        return Object.keys(filters).reduce(((values, filterKey) => {
          return values && deal[filterKey] === filters[filterKey]
        }), true)
      })
    }

    return dealsData
  }, [list, flightDeals, filters])

  return [deals, !!list.fetching]
}

export default useFlightDeals
