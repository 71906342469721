import { removeHighIntentOffers } from 'actions/RecommendationActions'
import LayoutContainer from 'components/Common/LayoutContainer/LayoutContainer'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import FullWidthCarousel from 'components/Luxkit/Carousel/FullWidthCarousel'
import Heading from 'components/Luxkit/Typography/Heading'
import { useAppDispatch } from 'hooks/reduxHooks'
import uuidV4 from 'lib/string/uuidV4Utils'
import { rem } from 'polished'
import React, { useCallback, useMemo } from 'react'
import { connect } from 'react-redux'
import { getHighIntentOffers } from 'selectors/recomendations/highIntentSelectors'
import styled from 'styled-components'
import HighIntentOfferCard from './HighIntentOfferCard'

const SectionContainer = styled(VerticalSpacer)`
  padding-top: ${rem(24)};
  padding-bottom: ${rem(24)};
`

const WrappedHighIntentOfferCard = styled(HighIntentOfferCard)`
  width: ${rem(368)};
`

interface Props {
  highIntentOffers: Array<App.HighIntentOffer>;
  analyticsLabel?: string;
}

function HomepageHighIntentOffers(props: Props) {
  const {
    highIntentOffers,
    analyticsLabel,
  } = props
  const dispatch = useAppDispatch()

  const tracking = useMemo(() => ({
    category: 'High Intent Offers',
    listName: 'Home Page High Intent Offers',
    listId: uuidV4(),
    label: analyticsLabel ?? '',
  }), [analyticsLabel])

  const onClose = useCallback((offerId: string, category: App.HighIntentCategory) => {
    dispatch(removeHighIntentOffers([offerId], [category]))
  }, [dispatch])

  if (highIntentOffers.length === 0) {
    return null
  }

  return (
    <SectionContainer gap={20}>
      <LayoutContainer>
        <Heading variant="heading5">Still interested in these offers?</Heading>
      </LayoutContainer>
      <FullWidthCarousel pageSize={3} snap="start" itemsPerArrow={3}>
        {highIntentOffers.map((highIntentOffer, index) => (
          <WrappedHighIntentOfferCard
            position={index + 1}
            highIntentOffer={highIntentOffer}
            tracking={tracking}
            key={highIntentOffer.offerId}
            onClose={onClose}
          />
        ))}
      </FullWidthCarousel>
    </SectionContainer>
  )
}

function mapStateToProps(state: App.State): Props {
  return {
    highIntentOffers: getHighIntentOffers(state),
  }
}

export default connect(mapStateToProps)(HomepageHighIntentOffers)
