import React, { Component } from 'react'
import styled from 'styled-components'
import Image from 'components/Common/Image'

import getDatasetFromEvent from 'lib/analytics/getDatasetFromEvent'
import ClickableLink from 'components/Common/Clickable/ClickableLink'
import { addGTMEvent } from 'api/googleTagManager'
import { promotionBanner } from 'analytics/promotions'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'

const ImageWrapper = styled(Image)`
  max-width: 100%;
  max-height: fit-content;
`

interface Props {
  banners: Array<App.Banner | App.ListBanner>;
  onBannerClick?: (banner: App.ListBanner) => void;
  onBannerLoad?: (banner: App.ListBanner) => void;
  onClickOpenNewTab?: boolean;
}

interface BannerMap {
  web: App.ListBanner;
  mobile: App.ListBanner;
}

class Leaderboard extends Component<React.PropsWithChildren<Props>> {
  bannerClick = e => {
    const bannerId = getDatasetFromEvent(e, 'bannerid')
    addGTMEvent(promotionBanner({ bannerId, placements: this.props.banners, hitType: 'promotion-click' }))
    this.props?.onBannerClick?.(this.props.banners[0])
  }

  bannerImpression = e => {
    const bannerId = getDatasetFromEvent(e, 'bannerid')
    addGTMEvent(promotionBanner({ bannerId, placements: this.props.banners, hitType: 'promotion-impression' }))
    this.props?.onBannerLoad?.(this.props.banners[0])
  }

  render() {
    const { banners, onClickOpenNewTab } = this.props
    const { web, mobile } = banners.reduce((map, placement: App.Banner | App.ListBanner) => {
      if (('display' in placement && placement.display === 'mobile') ||
        ('mobileImageId' in placement && placement.mobileImageId)) {
        map.mobile = placement
      }
      if (('display' in placement && placement.display === 'desktop') ||
        ('desktopImageId' in placement && placement.desktopImageId)) {
        map.web = placement
      }
      return map
    }, {} as BannerMap)

    const bannerId = web?.bannerId || mobile?.bannerId

    if (!bannerId) {
      return null
    }

    return <div id={`leaderboard-${bannerId}`}>
      {mobile &&
        <CSSBreakpoint max="mobile">
          <ClickableLink
            onClick={this.bannerClick}
            data-bannerid={mobile.bannerId}
            href={mobile.href}
            to={mobile.to}
            target={onClickOpenNewTab ? '_blank' : '_self'}
          >
            <ImageWrapper
              loading="lazy"
              width={735}
              enhancedFormats={false}
              quality="good"
              onLoad={this.bannerImpression}
              data-bannerid={mobile.bannerId}
              id={mobile.imageId || mobile.mobileImageId}
              alt={mobile.imageAlt}
            />
          </ClickableLink>
        </CSSBreakpoint>
      }
      {web &&
        <CSSBreakpoint min="tablet">
          <ClickableLink
            onClick={this.bannerClick}
            data-bannerid={web.bannerId}
            href={web.href}
            to={web.to}
            target={onClickOpenNewTab ? '_blank' : '_self'}
          >
            <ImageWrapper
              loading="lazy"
              width={1140}
              enhancedFormats={false}
              quality="good"
              onLoad={this.bannerImpression}
              data-bannerid={web.bannerId}
              id={web.imageId || web.desktopImageId}
              alt={web.imageAlt}
            />
          </ClickableLink>
        </CSSBreakpoint>
      }
    </div>
  }
}

export default React.memo(Leaderboard)
