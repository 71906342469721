import React, { useEffect, useMemo, useState } from 'react'
import cn from 'clsx'
import FlightDealImageTile from 'components/Flights/FlightMerchandising/FlightDealImageTile'
import Heading from 'components/Luxkit/Typography/Heading'
import useFlightDealLocations from 'home/hooks/useFlightDealLocations'
import LayoutContainer from 'components/Common/LayoutContainer'
import { MAX_CAROUSEL_ITEM_COUNT } from '../OfferCarousels/common/constants'
import { sortBy, take, uniqueBy } from 'lib/array/arrayUtils'
import FullWidthCarouselWithAnalytics from 'components/WithAnalytics/Carousel/FullWidthCarouselWithAnalytics'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import FlightDealAirportSelect from './FlightDealAirportSelect'
import { rem } from 'polished'
import Group from 'components/utils/Group'
import FlightDealTileSkeletonLoader from './FlightDealSkeletonLoader'
import styled from 'styled-components'
import TextButton from 'components/Luxkit/Button/TextButton'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { mediaQueryUp } from 'components/utils/breakpoint'
import Image from 'components/Common/Image'
import { useScreenSizeUp } from 'hooks/useScreenSize'
import { MAX_MOBILE_CAROUSEL_ITEM_COUNT } from 'constants/flight'

const LookingForSomethingElseContainer = styled(Group)`
  background-color: ${props => props.theme.palette.neutral.default.seven};
  padding: ${rem(12)} ${rem(20)};
  width: ${rem(275)};

  &.hide-mobile {
    display: none;
  }

  ${mediaQueryUp.tablet} {
    padding: ${rem(24)} ${rem(40)};

    &.hide-mobile {
      display: flex;
    }
  }
`

const Content = styled(Group)`
  text-align: center;
`

const StyledFlightDealTileSkeletonLoader = styled(FlightDealTileSkeletonLoader)`
  width: ${rem(275)};
`

interface Props {
  flightDeals: Array<App.FlightDeal>;
  flightDealsLoading: boolean;
}

function FlightDeals(props: Props) {
  const { flightDeals, flightDealsLoading } = props
  const [airports, defaultAirport] = useFlightDealLocations()
  const [selectedAirport, setAirport] = useState<App.Airport | undefined>()
  const [listLimit, setListLimit] = useState<number>(MAX_MOBILE_CAROUSEL_ITEM_COUNT)
  const isTabletScreenSizeAndHigher = useScreenSizeUp('tablet')

  const airport = selectedAirport ?? defaultAirport
  const filteredFlightDeals = useMemo(() => {
    if (airport) {
      const deals = flightDeals.filter(({ originAirportCode, heroImage }) => originAirportCode === airport.code && heroImage?.id)
      return sortBy(deals, (deal) => deal.hierarchy, 'asc')
    }
    return []
  }, [airport, flightDeals])

  const hasMultipleAirlines = useMemo(() => uniqueBy(filteredFlightDeals, deal => deal.carrier.code).length > 1, [filteredFlightDeals])

  useEffect(() => {
    if (isTabletScreenSizeAndHigher) {
      setListLimit(MAX_CAROUSEL_ITEM_COUNT)
    } else {
      setListLimit(MAX_MOBILE_CAROUSEL_ITEM_COUNT)
    }
  }, [isTabletScreenSizeAndHigher])

  return (
    <VerticalSpacer gap={20}>
      <LayoutContainer>
        <Group direction="vertical" gap={16}>
          {!!filteredFlightDeals.length && (
            <Group direction="horizontal" gap={8} verticalAlign="end">
              <Heading variant="heading4">Flights sale with{hasMultipleAirlines && ' our partner airlines'}</Heading>
              {!hasMultipleAirlines && <Image image={filteredFlightDeals[0].carrier.logo} width={100} alt={`Logo of ${filteredFlightDeals[0].carrier.name}`} />}
            </Group>
          )}
          <Group direction="horizontal" horizontalAlign="space-between" verticalAlign="center" gap={16}>
            <Group direction="vertical" tabletDirection="horizontal" tabletGap={8}>
              <Heading variant="heading2">{`Early Bird flights ${airports.length > 1 ? ' from' : ''}`}</Heading>
              {airport && airports.length > 1 &&
                <FlightDealAirportSelect
                  selected={airport}
                  airports={airports}
                  onChange={setAirport}
                />
              }
            </Group>
            <TextButton kind="tertiary" size="medium" to="/flights">
              View all
            </TextButton>
          </Group>
        </Group>
      </LayoutContainer>
      <FullWidthCarouselWithAnalytics
        snap="start"
        itemsPerArrow={4}
      >
        {flightDealsLoading && <>
          <StyledFlightDealTileSkeletonLoader />
          <StyledFlightDealTileSkeletonLoader />
          <StyledFlightDealTileSkeletonLoader />
          <StyledFlightDealTileSkeletonLoader />
        </>}
        {!flightDealsLoading && (
          <>
            {take(filteredFlightDeals, listLimit).map((flightDeal, index) => (
              <FlightDealImageTile position={index} key={flightDeal.id} flightDeal={flightDeal} hasMultipleAirlines={hasMultipleAirlines} />
            ))}
            <LookingForSomethingElseContainer
              gap={16}
              direction="vertical"
              horizontalAlign="center"
              verticalAlign="center"
              className={cn({ 'hide-mobile': filteredFlightDeals.length >= 3 })}
            >
              <Content direction="vertical" gap={8} verticalAlign="center">
                <BodyText variant="large" weight="bold">Looking for a flight?</BodyText>
                <BodyText variant="medium">Search for all destinations for more amazing offers</BodyText>
              </Content>
              <TextButton kind="secondary" size="medium" shape="square" to="/flights">
                Search now
              </TextButton>
            </LookingForSomethingElseContainer>
          </>
        )}
      </FullWidthCarouselWithAnalytics>
    </VerticalSpacer>
  )
}

export default FlightDeals
