import React from 'react'
import { SEARCH_VERTICALS } from 'constants/search'
import GlobalSearchDropDown from './SearchDropDown/GlobalSearchDropDown'
import GlobalMobileSearchTabContentContainer from 'components/SearchV2/Components/Mobile/GlobalMobileSearchTabContentContainer'
import noop from 'lib/function/noop'
import { rem } from 'polished'
import styled from 'styled-components'
import TextButton from 'components/Luxkit/Button/TextButton'
import GlobalSearchContainerPane, { GlobalSearchContainerPaneContent } from 'components/Search/GlobalSearch/GlobalSearchContainerPane'

const StyledGlobalMobileSearchTabContentContainer = styled(GlobalMobileSearchTabContentContainer)`
  padding: ${rem(8)} 0;
`

interface Props {
  tabs: Map<SEARCH_VERTICALS, App.GlobalSearchVerticalTabV2>
  selectedTab: SEARCH_VERTICALS
  onTabSelect: (tab: SEARCH_VERTICALS) => void
}

function GlobalMobileInPageSearchV2(props: Props) {
  const {
    tabs,
    selectedTab,
    onTabSelect,
  } = props

  const selected = tabs.get(selectedTab)

  return <GlobalSearchContainerPane shadow displayMobile>
    <GlobalSearchContainerPaneContent>
      <GlobalSearchDropDown
        selectedTabKey={selectedTab}
        tabs={tabs}
        onTabSelect={onTabSelect}
      />
      {selected && <StyledGlobalMobileSearchTabContentContainer
          isInPage
          tab={selected}
          closeSearch={noop}
          showSearch={noop}
          onSearchValidate={noop}
          onSearch={selected.onSearch}
        />}
      <TextButton
        kind="primary"
        type={selected?.formId ? 'submit' : 'button'}
        form={selected?.formId}
        fit="full-width"
        onClick={selected?.onSearch}
      >
        Search
      </TextButton>
    </GlobalSearchContainerPaneContent>
  </GlobalSearchContainerPane>
}

GlobalMobileInPageSearchV2.defaultProps = {
  defaultTab: SEARCH_VERTICALS.HOTELS,
}

export default GlobalMobileInPageSearchV2
